<template>
  <section id="reset-password" class="route">
    <loader v-if="loading" />

    <form
      class="form-component"
      :disabled="loading"
      @submit.prevent="submitForm"
    >
      <fieldset>
        <!-- Form Legend -->
        <legend>
          <i class="fas fa-tools accent--text"></i>
          <span>&nbsp;{{ formTitle }}</span>
        </legend>

        <!-- Email -->
        <label for="userEmail" class="column align-start">
          <span :class="requiredField('userEmail')">
            {{ token ? "Your" : "Enter your" }} email
          </span>
          <input
            :disabled="token"
            @input="clearErrors"
            aria-label="Username"
            class="wide"
            name="userEmail"
            placeholder="e.g. jane@email.com"
            type="email"
            v-model="form.userEmail"
          />
        </label>

        <!-- Password fields -->
        <template v-if="token && email">
          <label for="password" class="column align-start">
            <span :class="requiredField('password')">New Password</span>
            <input
              @input="clearErrors"
              class="wide"
              name="password"
              placeholder="Enter new password"
              type="password"
              v-model="form.password"
            />
          </label>

          <label for="confirmPassword" class="column align-start">
            <span :class="requiredField('confirmPassword')">
              Confirm new password
            </span>
            <input
              :aria-invalid="form.confirmPassword !== form.password"
              @input="clearErrors"
              class="wide"
              name="confirmPassword"
              placeholder="Confirm password"
              type="password"
              v-model="form.confirmPassword"
            />
          </label>
        </template>

        <p
          :key="notification"
          :class="{ pulse: error, 'slide-in-left': !error, notification: true }"
        >
          {{ notification || error }}
        </p>

        <button
          class="button wide"
          :class="{ error, success: !error, outline: !token }"
          :disabled="!formIsValid || !form.userEmail || error"
          type="submit"
          @submit.prevent="submitForm"
        >
          <i class="fas fa-exclamation-circle accent--text" v-if="error"></i>
          {{ token ? "Reset my password" : "Begin reset" }}
        </button>
      </fieldset>
    </form>

    <router-link :to="{ name: 'Login' }">Return to login</router-link>
  </section>
</template>

<script>
import Loader from "../components/Loader.vue";
import FormsMixin from "../components/mixins/forms.mixin";
import PermissionsMixin from "../components/mixins/permissions.mixin";
import {
  beginforgotPassword,
  resetPassword,
  validateResetToken
} from "../models/user";
import session from "../session";

export default {
  name: "ResetPassword",

  components: { Loader },

  mixins: [FormsMixin, PermissionsMixin],

  data: () => ({ loading: true, notification: null, tokenValid: false }),

  computed: {
    email() {
      return this.$route.query.email || null;
    },
    formIsValid() {
      return this.token
        ? this.form.password === this.form.confirmPassword
        : this.invalidFields.length === 0;
    },

    formTitle() {
      return this.token ? "Reset Password" : "Forgot Password?";
    },

    requiredFields() {
      return this.token ? ["password", "confirmPassword"] : ["userEmail"];
    },

    token() {
      return this.$route.query.token || null;
    }
  },

  async mounted() {
    await session.logout();
    if (this.email) this.appendFormData({ userEmail: this.email });
    if (this.token) {
      try {
        const { email, token } = this;
        await validateResetToken({ email, token });
        this.tokenValid = true;
      } catch (error) {
        this.onFormError(error.message || error);
      }
    }
    this.loading = false;
  },

  methods: {
    async beginPasswordReset() {
      try {
        this.loading = true;
        const params = { email: this.form.userEmail };
        const { status } = await beginforgotPassword(params);
        this.notification = status;
        this.loading = false;
      } catch (error) {
        this.onFormError(error);
      }
    },

    async completePasswordReset() {
      try {
        this.loading = true;
        const { userEmail, password } = this.form;
        const params = { email: userEmail, password, token: this.token };
        const { message } = await resetPassword(params);
        this.notification = message;
        this.loading = false;

        window.setTimeout(() => this.$router.push({ name: "Login" }), 3000);
      } catch (error) {
        this.onFormError(error);
      }
    },

    /* Override: clear 'notification' as well as 'error' property */
    clearErrors() {
      this.notification = null;
      this.error = null;
    },

    submitForm() {
      this.validateForm();

      // Stop if form is invalid
      if (this.error) return;

      if (this.token) {
        if (this.tokenValid) return this.completePasswordReset();

        // Stop if the 'reset password' token (url params) wasn't validated
        const msg =
          `Invalid token: this request cannot be completed.\n` +
          `Please contact ${this.appOrgName} for assistance.`;
        return (this.error = msg);
      }

      // Submit the value
      return this.beginPasswordReset();
    }
  }
};
</script>

<style lang="scss">
#reset-password {
  @include flex-column;
  @include flex-center;

  form {
    max-width: 24rem;
    width: $parent;

    fieldset {
      @include elevated;
      padding-bottom: $sm;
      padding-left: $sm;
      padding-right: $sm;
      width: $parent;
    }
  }

  .notification {
    align-items: center;
    display: flex;
    font-size: smaller;
    height: $xlg;

    &.pulse {
      color: $error;
    }

    &.slide-in-left {
      color: $success;
    }
  }
}
</style>
